export const intro = `As I sit and prepare myself to start writing, I notice a squirrel in the garden, frantically burying the peanuts that I've just thrown out for him. My intention was for him to eat the treats rather than stock up for the winter but hey ho. It may have been prudent thinking on the little fella's behalf but as there's a magpie following him from hole to hole, snaffling his stores, it sheds light on the saying “live for today”.`
	
export const paragraph1 = `I was born in 1965 and grew up within the suburbs of Birmingham, UK. Times were hard, not just for myself and my family but for most folk. Of course, occasionally you came across people who had money but generally the gap between the poor and the well offs wasn't as obvious as it is today.
I was what was known as a latch key kid. This meant after walking home from school, even as early as the age of six, I would reach through the letter box, grab the string dangling behind the door and retrieve the door key on the end of it. I suppose my parents would have wanted a better arrangement but as they both had to work, there weren't many other options.
My primary school wasn't memorable and as long as you didn't eat the crayons or lick the windows you passed under the radar. My five years at secondary school were more of a rodeo ride however. Because I spent my first year fighting bullies and playing truant, I found myself in the remedial class for English. We called it the dummy's class and there was a weird sort of bragging rights that went with the group. 
It wasn't an ideal start but as the years went by I clawed myself up to the middle of the pack and finally left school with five CSEs (precursor to GCSEs). It should have been six but my parents took me away on holiday when I should have been doing my Geography exam.
We didn't have much of a choice when it came to selecting the subjects that we wanted to pursue. English, Maths and a Science were compulsory and my three choices were Woodwork, which I genuinely enjoyed, Technical Drawing, which I found myself to be good at, and Geography, which seemed to be the best of the rest. 	
A' levels were a rarity in my school and even O' levels were scarce. No one that I knew went to college let alone university. Only doctors or super-nerds went to those establishments.`

export const paragraph2 = `At the tender age of sixteen I finally got away from the dark sarcasm and the dreaded thought control, as Pink Floyd would say. I was an adult and I needed a job to get money for beer, obviously.
Within days, I found myself serving petrol at a garage for ninety pence an hour, Monday to Saturday and sixty hours a week. Knowing nothing else, I was just happy to get all that cash at the end of the week (we were always paid in cash in those days). The year rolled on, I gained my driving licence and eventually moved into the plumbing industry where I remained for the next thirty-seven years.	
In 2007, after becoming restless, I tried my hand at higher education study and found it to be really enjoyable. In the proceeding years I studied subjects such as Art, Psychology, the Social Sciences and Maths with the Open University, all as brain food rather than directed towards a career change. I know my limitations and I'm really not that smart anyway.	
In 2016 I underwent open heart surgery for a quadruple by-pass and found that I had a lot of spare recovery time to fill. This, as crazy as it seems, was the kick up the backside I needed to start my writing journey.
Having been an avid book reader for most of my later life, I decided it was time to try my hand at writing while I rehabilitated. Of course, my middle grade English CSE isn't the ideal tool for writing a best seller but my ignorance is a Godsend. It took me two years to write that first 130,000 word novel but I was over the moon about it, so much so that I wrote another 100 thousand plus novel. As a result of putting so many words onto a word processor screen, I found my vocabulary increasing and my punctuation and grammar getting betterer.
Ten thousand words into my third novel, and four years after starting writing, I finally realised that these stories were just thoughts unless I could get them published. It was time to put my big boy's pants on and begin editing them in preparation for the ultimate goal - a printed book with my name on it.
January 2020 I became redundant. It was the start of the COVID19 lock-down and once again, I had time on my hands. My first novel needed far too much work to drag it up to a level that I would be happy to present to Joe Public so I decided the second novel would be less gruelling. Eventually, after a whole year of editing, “RON” was released via Amazon KDP in both kindle and print options. The first book, “A Nation's Cry” will follow shortly and the third book is also under the pen, so to speak.
I now work as part of the ancillary staff for the NHS. It's not exciting by any stretch of the imagination but I like that. It allows me the time to drip feed my imagination with the contents of the next chapter that I'm writing.
It's been a long path and one that I never consciously set out to walk upon but I'm glad that I did. Having the confidence to blurt out your ideas to others is a fragile thing. No doubt, my ignorance and low expectations have helped me have the courage to offer my stories to an audience. I have so much more respect for any and every author who puts their work out there.`;