import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import sizeConfigs from '../../configs/sizeConfigs';

const StandardImageList = () => {
  return (
    <ImageList sx={{ width: '100%',  height: 'auto' }} variant='woven' cols={3} rowHeight={164}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}`} //?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            srcSet={`${item.img}`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: '/gallery-1.jpeg',
    title: 'Gallery 1',
  },
  {
    img: '/gallery-2.jpeg',
    title: 'Gallery 2',
  },
  {
    img: '/gallery-3.jpeg',
    title: 'Gallery 3',
  },
  {
    img: '/gallery-4.jpeg',
    title: 'Gallery 4',
  },
  {
    img: '/gallery-5.jpeg',
    title: 'Gallery 5',
  },
  {
    img: '/gallery-6.jpeg',
    title: 'Gallery 6',
  },
  {
    img: '/gallery-7.jpeg',
    title: 'Gallery 7',
  },
  {
    img: '/gallery-8.jpeg',
    title: 'Gallery 8',
  },
  {
    img: '/gallery-9.jpeg',
    title: 'Gallery 9',
  },
];

export default StandardImageList;