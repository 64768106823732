import { Box, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import React from 'react';
import sizeConfigs from '../../configs/sizeConfigs';
import Sidebar from '../common/Sidebar2';
import colourConfigs from '../../configs/colourConfigs';

const MainLayout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${sizeConfigs.sidebar.width})`,
          minHeight: "100vh",
          backgroundColor: colourConfigs.mainBg
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default MainLayout;
