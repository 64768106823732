import React from "react";

type Props = {};

const HomePage = (props: Props) => {
    return (
        <div>
            <h1>Philip Payne Website</h1>
            <p>Currently under construction</p>
        </div>
    );
};

export default HomePage;