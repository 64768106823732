import ComparisonSlider from '../../components/common/ComparisonSlider';

const SelfPortraitPageLayout = () => {
  return (
    <div>
      <h1>Self Portrait</h1>
      <p>Phil's self portrait. Can you tell which is which?</p>
      <ComparisonSlider topImage='/selfPortrait-photo.jpeg' bottomImage='/selfPortrait-art.jpeg' />
    </div>
  );
};

export default SelfPortraitPageLayout;
