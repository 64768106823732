import { RouteType } from './config';
import HomePage from '../pages/home/HomePage';
import AuthorPageLayout from '../pages/author/AuthorPageLayout';
import RonPageLayout from '../pages/author/RonPageLayout';
import ANationsCryPageLayout from '../pages/author/ANationsCryPageLayout';
import BioPageLayout from '../pages/bio/BioPageLayout';
import {
  Brush,
  MenuBook,
  TextSnippet,
  Home,
} from '@mui/icons-material';
import GalleryPageLayout from '../pages/artist/GalleryPageLayout';
import SelfPortraitPageLayout from '../pages/artist/SelfPortraitPageLayout';
import ArtIndex from '../pages/artist/ArtIndex';

const appRoutes: RouteType[] = [
  {
    index: true,
    path: '/home',
    element: <HomePage />,
    state: 'home',
    sidebarProps: {
      displayText: 'Home',
      icon: <Home />,
    }
  },
  {
    path: '/bio',
    element: <BioPageLayout />,
    state: 'bio',
    sidebarProps: {
      displayText: 'Bio',
      icon: <TextSnippet />,
    },
  },
  {
    path: '/bibliography',
    element: <AuthorPageLayout />,
    state: 'bibliography',
    sidebarProps: {
      displayText: 'Bibliography',
      icon: <MenuBook />
    },
    child: [
      {
        path: '/bibliography/ron',
        element: <RonPageLayout />,
        state: 'bibliography.ron',
        sidebarProps: {
          displayText: 'Ron',
        },
      },
      {
        path: '/bibliography/a-nations-cry',
        element: <ANationsCryPageLayout />,
        state: 'bibliography.anc',
        sidebarProps: {
          displayText: 'A Nations Cry',
        },
      },
    ],
  },
  {
    path: '/artist',
    element: <ArtIndex />,
    state: 'artist',
    sidebarProps: {
      displayText: 'Artist',
      icon: <Brush />
    },
    child: [
      {
        path: '/artist/self-portrait',
        element: <SelfPortraitPageLayout />,
        state: 'artist.selfPortrait',
        sidebarProps: {
          displayText: 'Self Portrait',
        },
      },
      {
        path: '/artist/gallery',
        element: <GalleryPageLayout />,
        state: 'artist.gallery',
        sidebarProps: {
          displayText: 'Gallery',
        },
      },
    ],
  }
];

export default appRoutes;
