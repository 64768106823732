import React from 'react';

type Props = {};

const ANationsCryPageLayout = (props: Props) => {
  return (
    <div>
      <h1>A Nations Cry</h1>
      <p>
        Coming soon, stay tuned for updates!
      </p>
    </div>
  );
};

export default ANationsCryPageLayout;
