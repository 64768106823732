import * as React from 'react';
import {
  styled,
  useTheme,
  Theme,
  CSSObject,
} from '@mui/material/styles';
import { Link } from 'react-router-dom';
import {
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  Menu,
  Mail as MailIcon,
  ExpandMore,
} from '@mui/icons-material';
import appRoutes from '../../routes/appRoutes';
import { RouteType } from '../../routes/config';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  height: '5%',
  justifyContent: 'center',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '5%',
    justifyContent: 'center',
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const CollapsableList = (route: RouteType, index: number) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(!open);

  return (
  <ListItem sx={{
    padding: 0,
    color: '#FFFFFF',
  }} key={index}>
    <ListItemButton onClick={handleClick}>
      <ListItemIcon sx={{color: '#FFFFFF'}}>
        {route.sidebarProps?.icon}
      </ListItemIcon>
      {!open ? <ListItemText primary={route.sidebarProps?.displayText} /> : null}
      {open ? null : <ExpandMore />}
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit style={{alignContent: 'center'}}>
      <List component="div" disablePadding >
      {route.child?.map((child, index) => (
        child.sidebarProps ? Item(child, index, true) : null
      ))}
      </List>
    </Collapse>
  </ListItem>
  );
}

const Item = (route: RouteType, index: number, child: boolean = false) => {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => setOpen(!open);
  return (
    <ListItem
      key={route.sidebarProps?.displayText}
      disablePadding
      sx={{
        display: 'block',
        padding: 0,
        color: '#FFFFFF',
      }}
    >
      <ListItemButton
        component={Link}
        to={route.path}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          // justifyContent: child ? 'right' : 'initial',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
            color: '#FFFFFF',
          }}
        >
          {route.sidebarProps?.icon}
        </ListItemIcon>
        <ListItemText primary={route.sidebarProps?.displayText} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
}

export default function MiniDrawer() {
  const theme = useTheme();
  theme.palette.primary.main = '#870505';
  theme.palette.background.paper = '#c33e37';
  theme.palette.text.primary = '#000000';
  theme.palette.text.secondary = '#FFFFFF';
  theme.palette.secondary.main = '#FFFFFF';

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color={'inherit'}
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Philip Payne
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{color: theme.palette.text.secondary}}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {appRoutes.map((route, index) => (
            route.child
              ? CollapsableList(route, index)
              : Item(route, index)
          ))}
          <Divider />
        </List>
        <List>
          <ListItem key='contact' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                color: theme.palette.text.secondary,
              }}
              component="a"
              href="mailto:philip.payne40@gmail.com"
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: theme.palette.text.secondary
                }}
              >
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary='Get in touch' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}