import React from 'react';
import { intro, paragraph1, paragraph2 } from '../../bio';
import { Typography, Box, colors } from '@mui/material';

type Props = {};

const BioPageLayout = (props: Props) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyItems={'center'}
      // sx={{
      //   backgroundColor:'#fbe9e7'
      // }}
    >
      <h1>Bio</h1>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyItems={'center'}
        maxWidth='90%'
        // sx={{
        //   backgroundColor:'#fbe9e7'
        // }}
      >
        <Typography className='bioText'>{intro}</Typography>
        <img className='pageImage' src='/portrait.jpeg' alt='Phil looking content' />
        <Typography className='bioText'>{paragraph1}</Typography>
        <img className='pageImage' src='/phil-concentrating.jpeg' alt='Phil concentrating over a table' />
        <Typography className='bioText'>{paragraph2}</Typography>
        <img className='pageImage' src='/phil-mug.jpeg' alt='Phil holding a "worlds best dad" mug' />
      </Box>
    </Box>
  );
};

export default BioPageLayout;
