import { Typography } from '@mui/material';
import React from 'react';

type Props = {};

const RonPageLayout = (props: Props) => {
  return (
    <Typography sx={{
      flexWrap: 'wrap',
    }}>
      <h1>Ron</h1>
      <sub>ISBN: 979-8455389184</sub>
      <br />
      <a href='https://www.amazon.co.uk/RON-Philip-Payne/dp/B09CKL2TC3' target='_blank'>Click here to purchase (opens in a new tab)</a>
        <p>
          The first book published by Phil follows the story of the titular character.
          <br />
          <br />
          After trying to break up a bar fight, Ron finds himself wrongly accused of assaulting a couple of police officers. He serves his time and all he wants now is to settle down into a simple life and be left alone. The only trouble is, he has a habit of being in the wrong place at the wrong time.
          <br />
          <br />
          With his heart in the right place, he is never one to shy away from offering help to any who need it, even though he knows the good deed will mostly backfire.
          <br />
          <br />
          Shortly after being released from prison Ron finds himself at rock bottom. Surely his luck can only take him in one direction from now. How wrong he is.
          <br />
          <br />
          Recruited to carry out questionable deeds by a questionable government department, Ron finds himself walking a thin line between right and wrong. That very line becomes smudged when he meets a woman standing on the other side who makes him question his actions. 
        </p>
      <img className='pageImage' src='/phil-holding-ron.jpeg' alt='Phil in a red shirt holding his book, Ron' />
    </Typography>
  );
};

export default RonPageLayout;
